import React from "react"
import { useAuth0 } from "@auth0/auth0-react"

function SendForm() {
	const {
		isLoading,
		isAuthenticated,
		error,
		loginWithRedirect,
	} = useAuth0();

	if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Oops... {error.message}</div>;
	}
	
	if (isAuthenticated) {
    return (
      <div>
				<input type="text" placeholder="Mobile Number" />
				<button>Send</button>
      </div>
    );
  } else {
    return <button onClick={loginWithRedirect}>Sign In</button>;
  }
}

export default SendForm
